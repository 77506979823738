@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

/*scroll*/
::selection {
  background: #895ffc;
  color: #fff;
  text-shadow: none;
}

::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-button:start:decrement,
::-webkit-scrollbar-button:end:increment {
  display: none;
}

::-webkit-scrollbar-track-piece {
  background-color: #dedede;
}

::-webkit-scrollbar-thumb:vertical {
  border-radius: 0px;
  height: 20px;
  background-image: linear-gradient(#295377, #208c74);
}
.chatUsersList::-webkit-scrollbar {
  width: 2px;
}

/* body{overflow-y: hidden;} */

body,
h1,
h2,
h3,
h4,
h5,
h6,
table,
thead,
tbody,
tr,
td,
th,
span {
  font-family: 'Outfit' !important;
}

/* h1,
h2,
h3,
h4,
h5,
h6,
p {
    user-select: none;
} */

a {
  text-decoration: none !important;
}

.applyForm label {
  color: #788094;
  font-size: 14px;
  transition: 0.3s;
}
.applyForms label {
  color: #788094;
  font-size: 14px;
  transition: 0.3s;
}
.applyForm > div > div {
  margin: 0 0 20px 0 !important;
}

.applyForm > div {
  margin: 0 0 10px 0 !important;
}

.applyForm input,
.applyForm textarea,
.applyForm .react-international-phone-input-container,
.applyForm select {
  border: 1px solid #75767a;
  transition: 0.3s;
  border-radius: 12px;
  height: 52px;
}
.applyForms input,
.applyForms textarea,
.applyForms .react-international-phone-input-container,
.applyForms select {
  border: 1px solid #75767a;
  transition: 0.3s;
  border-radius: 12px;
  height: 52px;
}
.applyForm .react-international-phone-input-container {
  height: unset;
  padding: 6px 10px;
}

.applyForm .react-international-phone-country-selector button {
  border: none;
}
.applyForms .react-international-phone-input-container {
  height: unset;
  padding: 6px 10px;
}

.applyForms .react-international-phone-country-selector button {
  border: none;
}

.applyForm > div > div:hover label {
  color: #208c74;
}

.applyForm > div > div:hover input {
  border-color: #208c74;
}
.applyForms > div > div:hover label {
  color: #208c74;
}

.applyForms > div > div:hover input {
  border-color: #208c74;
}
.checkboxFamily > div > div {
  border: 1px solid #75767a;
  padding: 15px;
  border-radius: 12px;
}

.applyForm > div:hover label {
  color: #208c74;
}

.applyForm > div:hover input {
  border-color: #208c74;
}
.applyForms > div:hover label {
  color: #208c74;
}

.applyForms > div:hover input {
  border-color: #208c74;
}
.modalBody p {
  margin-bottom: 10px;
}
.chakra-menu__menu-button + div {
  z-index: 2 !important;
}

.chat-container {
  height: auto;
  overflow-y: auto;
}
.multis .css-3w2yfm-ValueContainer ,.multis  .css-1fdsijx-ValueContainer{
  padding: 0 8px;
}
.multis  .css-qbdosj-Input
{
  margin: 0;  
    padding-bottom: 0;
      padding-top: 0 ;
}
.multis .css-13cymwt-control,.multis .css-t3ipsp-control
{
  border: 1px solid #75767a;
    transition: 0.3s;
    border-radius: 12px;
}